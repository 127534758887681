<template>
    <div>
        <BaseInfo :baseInfo="$store.state.user.baseInfo" />
        <NavBar
                title="在线沟通"
                left-arrow
                @click-left="$router.go(-1)"
        />
        <div v-if="!user_empty">
            <div   v-for="(doctor,idx) in doctors"  :key="idx" >
                <vcell style="margin-top: 10px" is-link @click="toChat(doctor.id)">
                    <div style="float: left;width: 30%">
                        <vimg
                                width="100"
                                height="70"
                                src="https://img01.yzcdn.cn/vant/cat.jpeg"
                        />
                    </div>
                    <div style="float: left;width: 70%;margin-top: 5px">
                        <div class="text" style="text-align: center">
                            <span style="font-size: larger">{{doctor.name}}</span>
                        </div>
                        <div class="text" style="text-align: center;margin-top: 5px">
                            <span style="font-size: small">主任医师，XXX介绍</span>
                        </div>
                    </div>
                </vcell>
            </div>
        </div>

        <empty v-if="nodata&&!user_empty" description="查无数据" />
        <Loading type="spinner" color="#1989fa" size="24px" vertical v-if="user_empty" style="margin-top: 40%">获取授权中...</Loading>

        <RLFooter/>
    </div>
</template>

<script>
    // import {getDoctors} from '@/api/chat';
    import {getDoctors} from '@/api/doctor/baseinfo';

    // import {getBaseInfo} from '@/api/patient/baseinfo';
    import {setToken,getToken,removeToken} from '@/utils/auth';
    import {getUser} from '@/api/user';

    import {Cell as vcell,Empty,Image as vimg,Loading} from 'vant';

    export default {
        name: 'QueIdx',
        components: {
            vcell,Empty,vimg,Loading
        },
        data(){
            return {
                doctors:[],
                nodata:true,
                user:{
                    userName:'',
                    nickName:'',
                    idCard:'',
                    phone:'',
                    gender:'1',
                    birthday:'',
                    id:'',
                    pid:'',
                },
                user_empty:true

            };
        },
        computed:{

        },
        methods:{
            initidx(){
                console.info(this.$store.state.user);
                //this.$store.commit('updatePid',this.$route.params.pid);
                // let query = this.$route.query;

                // let follow_time=query.follow_time?query.follow_time : this.parseTime(new Date(),'{y}-{m}-{d}');
                // console.log(follow_time)
                getDoctors({pid:this.$store.state.user.pid}).then((response) => {
                    if(response.code==200 ){
                        console.log(response)
                        this.doctors = response.data.patientUsers
                    }
                });
            },
            toChat(userid){
                console.log(userid)
                this.$router.push({path: '/tochat/'+userid});
            },
            initBaseInfo(){
                getDoctors({pid:this.user.id}).then((response) => {
                    if(response.code==200 ){
                        console.log(response)
                        this.doctors = response.data

                        if(this.doctors.length  == 0){
                            this.nodata = true
                        }else{
                            this.nodata = false
                        }
                    }
                });
            },
        }
        ,
        created() {
          // 开发环境
          if(process.env.NODE_ENV=='development'){
            getUser().then((res) => {
              console.info(res.data);

              if(res.data){
                this.$store.commit('updateOpenid',res.data.openid);
                this.$store.commit('updatePid',res.data.id);
                this.$store.commit('updateBaseInfo',res.data);
                if(res.data.name !=''){
                  this.substatus = true
                }
                this.user.id=res.data.id;
                this.user.name=res.data.name;
                this.user.birthday=res.data.birthday;
                // this.user.idCard=response.data.idCard;
                this.user.telephone=res.data.telephone;
                this.user.gender=res.data.gender+'';
                this.user.address=res.data.address;
                this.user.detail_address=res.data.detail_address;
                // this.initBaseInfo();
                this.user_empty = false

                this.initBaseInfo();

              }
            });
          }else{
            //生产环境
            var query = this.$route.query;
            let codeTemp = getToken();

            if(query.code || this.getQueryString('code') || codeTemp){
              // console.log("code")
              // console.log(query.code)
              // console.log(this.getQueryString('code'))
              //this.$store.commit('updateOpenid','123456');
              if(query.code || this.getQueryString('code')){
                this.$store.commit('updateCode',query.code?query.code : this.getQueryString('code'));
                setToken(query.code?query.code : this.getQueryString('code'));
              }
              getUser().then((res) => {
                console.info(res.data);

                if(res.data){
                  this.$store.commit('updateOpenid',res.data.openid);
                  this.$store.commit('updatePid',res.data.id);
                  this.$store.commit('updateBaseInfo',res.data);
                  if(res.data.name !=''){
                    this.substatus = true
                  }
                  this.user.id=res.data.id;
                  this.user.name=res.data.name;
                  this.user.birthday=res.data.birthday;
                  // this.user.idCard=response.data.idCard;
                  this.user.telephone=res.data.telephone;
                  this.user.gender=res.data.gender+'';
                  this.user.address=res.data.address;
                  this.user.detail_address=res.data.detail_address;
                  // this.initBaseInfo();
                  this.user_empty = false

                  this.initBaseInfo();

                }
              });
              //getBaseInfo({}).then((response) => {
              //   console.info(response);
              // });
            }else{
              removeToken();
            }
          }

            // var query = this.$route.query;
            // if(query.code || this.getQueryString('code')){
            //     console.log("code")
            //     console.log(query.code)
            //     console.log(this.getQueryString('code'))
            //     //this.$store.commit('updateOpenid','123456');
            //     this.$store.commit('updateCode',query.code?query.code : this.getQueryString('code'));
            //     setToken(query.code?query.code : this.getQueryString('code'));
            //     getUser().then((res) => {
            //         if(res.data){
            //             console.info(res.data.openid);
            //             this.$store.commit('updateOpenid',res.data.openid);
            //             this.$store.commit('updatePid',res.data.pid);
            //             this.$store.commit('updateBaseInfo',res.data);
            //             this.user.id=res.data.id;
            //             this.user.pid=res.data.pid;
            //             this.user.nickName=res.data.nickName;
            //             this.user.userName=res.data.userName;
            //             this.user.birthday=res.data.birthday;
            //             this.user.gender=res.data.gender+'';
            //             if(res.data.userStatus == 1){
            //                 this.user_empty = false
            //                 // let follow_time=this.parseTime(new Date(),'{y}-{m}-{d}');
            //                 this.initBaseInfo();
            //
            //             }else{
            //                 this.user_empty = true
            //                 Toast.fail('授权失败\n请注册信息');
            //             }
            //             // this.initBaseInfo();
            //         }
            //     });
            //     //getBaseInfo({}).then((response) => {
            //     //   console.info(response);
            //     // });
            // }else{
            //     removeToken();
            // }
            /*初始化列表*/
            // this.initBaseInfo();

        },
    }
</script>

<style scoped>

    .is_completed{
        color: aqua;
    }
    .is_uncompleted{
        color:red;
    }

</style>
